<div class="property-details-pane">

    <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'header_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->
    <ad-unit [showOnDesktop]="false" page="property_details" adPosition="property_header"></ad-unit>

    <!-- Photos carousel -->
    <article class="photos content">
        <pb-carousel imageSizes="large" hasCounter="true" [images]="this.propertyDetails.photos" navigation="true" slidesPerView="1" (fullScreenClicked)="onFullScreenClicked()"></pb-carousel>
        <div class="no-photos" *ngIf="!this.propertyDetails.photos || this.propertyDetails.photos.length==0"></div>
    </article>

    <!-- Property price and details -->
    <article class="prop-details content">
        <h1 class="small">{{ propertyDetails.title }}</h1>
        <span class="subtitle1">{{ propertyDetails.address }}</span>
        <link-button class="see-on-map" title="See on map" i18n-title="See_On_Map|See on map action for property" beforeIcon="locationonmap" size="medium" (linkClicked)="onClickScroll('property-map')"></link-button>
        <div class="price">
            <span class="h1 small">{{ propertyDetails.price }}</span>
            <span *ngIf="propertyDetails.priceModifier">
                {{propertyDetails.priceModifier}}
            </span>
            <link-button *ngIf="this.isForSale" class="monthly-mortgage-payments" title="Monthly mortgage payments" i18n-title="Monthly_Mortgage_Payments|Link to how much mortgage payments are for a property" beforeIcon="calculate" size="medium" (linkClicked)="onClickScroll('mortgage-calculator')"></link-button>
            
            <!-- <link-button *ngIf="this.isForSale" class="affordability-check" nofollow = "true" title="Find out how much you can borrow" i18n-title="Find out how much you can borrow|Find out how much you can borrow" beforeIcon="pound" border="false" size="medium" href="https://www.onedome.com/services/mortgage-passport?utm_source=placebuzz&utm_medium=web&utm_campaign=mortgage_calculator" newWindow="true" externalLink="true"></link-button> -->
            <div *ngIf="!isForSale && propertyDetails.tenancyInfo" class="tenancy-info">
                <a href="javascript:void(0);" (click)="onTenancyInfoClicked($event)">
                    <span class="info">i</span>
                    <div i18n="Tenancy_Fees_Info|Tenancy fees info" class="caption">Tenancy fees info</div>
                </a>
            </div>
            

            <link-button class="affiliate-price-button" title="Check your credit score" border="true" size="small" href="https://www.creditengine.co.uk/?campaign_code=cF5ZYAMFAw&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_detail" nofollow="true" newWindow="true"></link-button>    

        </div>
    </article>

    <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'first_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->

    <ad-unit [showOnDesktop]="false" page="property_details" class="top-margin-ad" adPosition="property_incontent_1"></ad-unit>
    <ad-unit [showOnMobile]="false" page="property_details" class="top-margin-ad" adPosition="property_header"></ad-unit>

    <!-- Property Features -->
    <article class="prop-features content">
        <property-features [features]="propertyDetails.features"></property-features>
    </article>

    <!-- Favourite and Share -->
    <article class="actions content">
        <social-sharing class="social-sharing" #socialSharingMobile></social-sharing>
        <favourite-switch *ngIf="!isFavourite" i18n-caption="Save|Save" caption="Save" class="favourite-button" [placeId]="propertyDetails.listingId" [isSelected]="isFavourite" (requestSelectedChange)="onAddToFavouritesClicked($event)"></favourite-switch>
        <favourite-switch *ngIf="isFavourite" i18n-caption="Saved|Saved" caption="Saved" class="favourite-button" [placeId]="propertyDetails.listingId" [isSelected]="isFavourite" (requestSelectedChange)="onAddToFavouritesClicked($event)"></favourite-switch>
        <link-button class="share-button" title="Share" border="true" i18n-title="Share|Share" beforeIcon="share" size="medium" fullWidth="true" (linkClicked)="onShareClicked($event)"></link-button>
    </article>

    <!-- Floorplans -->
    <article class="floorplan-images content" *ngIf="propertyDetails.floorPlans && propertyDetails.floorPlans.length > 0" >
        <a routerLink="{{this.propertyDetails.detailsUrl}}/floorplans">
            <pb-image crop="false" class="floorplan" [src]="propertyDetails.floorPlans[0]" [alt]="propertyDetails.title + ' - floorplan'" width="300px" height="184px" imageWidth="300" imageHeight="184" ></pb-image>
        </a>
        <link-button class="more-floorplans" [title]="propertyDetails.floorPlans.length" beforeIcon="floorplan" border="true" size="medium" href="{{this.propertyDetails.detailsUrl}}/floorplans"></link-button>
    </article>

    <!-- Photos slider -->
    <article class="prop-images content" *ngIf="this.propertyDetails.photos && this.propertyDetails.photos.length>0" >
        
        <h2 class="small" i18n="Property_Photos|Property Photos">Property photos</h2>
        <a [routerLink]="this.propertyDetails.detailsUrl + '/images'">
            <image-carousel [images]="this.propertyDetails.photos"></image-carousel>
        </a>
    </article>

    <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'second_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->

    <!-- Property description -->
    <article class="prop-description section content">
        <div class="description">
            <h2 class="small" i18n="Property_Description|property description">Property description</h2>
            <div class="desc body1 relaxed" [class.truncated]="isDescTruncated" [innerHtml]="propertyDetails.description"></div>
            <link-button *ngIf="!isDescTruncated" class="more-desc" title="Read more" i18n-title="Read_More|Read more action for text" border="true" size="small" (linkClicked)="isDescTruncated = true;"></link-button>
            <link-button *ngIf="isDescTruncated" class="more-desc" title="Show less" i18n-title="Show_Less|Show less action for text" border="true" size="small" (linkClicked)="isDescTruncated = false;"></link-button>
        </div>
    </article>

    <!-- Contact Agent -->
    <article *ngIf="propertyDetails.showEnquiryForm" class="contact-prop section content">
        <h2 class="small" i18n="Interested_In_Property|Interested in this property?">Interested in this property?</h2>

        <div class="contact-buttons">
            <pb-button caption="Contact agent" i18n-caption="Contact_Agent|Contact agent button"  useRouterLink="true" actionTrackingLabel="Contact Agent" rel="nofollow" (clicked)="onContactAgentClicked($event)"></pb-button>
            <pb-button caption="Request more details" i18n-caption="Request_Details|Request more details button" isSecondary="true" useRouterLink="true" rel="nofollow" actionTrackingLabel="Request Details" (clicked)="onRequestDetailsClicked($event)"></pb-button>
        </div>

        <link-button class="affiliate-middle-button" [fullWidth]="true"  title="Check your credit score" border="true" size="small" href="https://www.creditengine.co.uk/?campaign_code=cF5ZYAMFAw&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_detail" nofollow="true" newWindow="true"></link-button>

    </article>

    <ad-unit [showOnMobile]="false" page="property_details" adPosition="property_incontent_1"></ad-unit>
    <ad-unit [showOnDesktop]="false" page="property_details" adPosition="property_incontent_2"></ad-unit>
    
    <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'third_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->

    <!-- Council Tax and Documents -->
    <article class="more-features section content">
        <h2 class="small" i18n="Council_Tax|Council Tax">Council tax</h2>
        <link-button title="Ask agent" i18n-title="Ask_Agent|Ask agent" size="medium" (click)="onRequestDetailsClicked($event)"></link-button>

        <h2 class="small first-listed" i18n="First_Listed|First listed on date">First listed</h2>
        <span>{{elapsedTimeDisplay}}</span>

        <h2 class="small first-listed" i18n="Energy Performance Certificate|Energy Performance Certificate" *ngIf="propertyDetails.documents && propertyDetails.documents.length>0">Energy Performance Certificate</h2>

        <div *ngIf="imageDocuments.length>0" class="epcs">
            <a *ngFor="let imageLink of imageDocuments" action-tracking action-tracking-values="Link,Click,Document Image" [routerLink]="this.propertyDetails.detailsUrl + '/documents'">
                <pb-image [src]="imageLink.url" imageWidth="64" imageHeight="64" width="100px" height="100px" [alt]="propertyDetails.title + ' - document'"></pb-image>
            </a>
        </div>
        <div *ngIf="downloadableDocuments.length>0" class="downloadables">
            <a *ngFor="let downloadLink of downloadableDocuments" href="{{downloadLink.url|media}}" target="_blank" title="{{downloadLink.title}}" action-tracking action-tracking-values="Link,Click,Document Download" open-browser-if-app>{{downloadLink.value}}</a>
        </div>
    </article>

    
    <!-- Property Map -->
    <article id="property-map" class="prop-map section content" intersection-observer observeMargin="40px" [onObservedCallback]="onShowMap">
        <h2 class="small">{{ propertyDetails.address }}</h2>
        <property-map *ngIf="showMap" [latLng]="this.latLng" mode="EMBED"></property-map>
        <!-- <div class="buttons">
            <link-button title="Open map" i18n-title="Open_Map|Open map action for property" beforeIcon="locationonmap" border="true" fullWidth="true" size="medium"></link-button>
            <link-button title="Street view" i18n-title="Street_View|Street view action for property" beforeIcon="streetview" border="true" fullWidth="true" size="medium"></link-button>
        </div> -->
    </article>

    <!-- <dcp-ad-v2 *ngIf="showAds" [doubleClickAd]="this.getAd('property_details', 'fourth_banner')" [showTestAd]="showTestAds"></dcp-ad-v2> -->

    <!-- Similar listings nearby -->
    <div intersection-observer [observeFirstChildElement]="false" observeMargin="200px" [onObservedCallback]="OnSimilarListingsObservedAction">
        <article *ngIf="propertyDetails.similarListings && propertyDetails.similarListings.length>0" class="similar-properties section content">
            <h2 class="small" i18n="Properties_On_The_Market_Nearby|Properties on the market nearby">Properties on the market nearby</h2>
            <property-carousel [properties]="propertyDetails.similarListings" imageSizes="small" navigation="true" slidesPerView="4.5" [spaceBetween]="12"></property-carousel>
            
            <link-button afterIcon="next" class="more-properties" title="See more properties like this one" i18n-title="See_More_Properties_Like_This_One|See more properties like this one" border="true" fullWidth="true" size="small" [href]="propertyDetails.similarPropertiesSearchUrl"></link-button>

        </article>
    </div>


    <!-- Marketed by -->
    <article *ngIf="propertyDetails.showEnquiryForm" class="marketed-by section content">
        <h2 class="small" i18n="Marketed_By|Marketed by">Marketed by</h2>
        <img *ngIf="listerLogoUrl" [src]="listerLogoUrl|safeUrl" />
        <span class="agent-name subtitle3">{{propertyDetails.agentName}}</span>
        <span class="agent-address" *ngIf="propertyDetails.agentAddress">
        {{propertyDetails.agentAddress}}
        </span>
        <div *ngIf="propertyDetails.agentPhoneNumber && propertyDetails.showEnquiryForm" class="phone-number">
            <span i18n="Call_Agent_On|Call agent on">Call agent on </span>
            <span>{{propertyDetails.agentPhoneNumber}}</span>
        </div>
        <link-button *ngIf="propertyDetails.showEnquiryForm" title="Contact agent" i18n-title="Contact_Agent|Contact agent button" beforeIcon="mail" border="true" fullWidth="true" size="small" (click)="onContactAgentClicked($event)"></link-button>
    </article>

    
    <ad-unit [showOnMobile]="false" page="property_details" adPosition="property_incontent_2"></ad-unit>


    <!-- Mortgage calculator - For sale only -->
    <article id="mortgage-calculator" *ngIf="isForSale && propertyDetails.mortgageEstimate" class="mortgage section content">
        <h2 class="small" i18n="Placebuzz_Mortgage_Repayment_Calculator|Placebuzz mortgage repayment calculator">Placebuzz mortgage repayment calculator</h2>
        <mortgage-calculator [mortgageEstimate]="propertyDetails.mortgageEstimate"></mortgage-calculator>
    </article>

    <!-- Sponsored properties -->
    <div intersection-observer [observeFirstChildElement]="false" observeMargin="200px" [onObservedCallback]="OnSponsoredListingsObservedAction">
        <article *ngIf="sponsoredListings && sponsoredListings.length>0" class="similar-properties section content" >
            <h2 class="small" i18n="Sponsored_Properties|Sponsored properties">Sponsored properties</h2>
            <property-carousel [properties]="sponsoredListings" imageSizes="small" navigation="true" slidesPerView="4.5" [spaceBetween]="12"></property-carousel>
        </article>
    </div>

    <!-- Streetview -->
    <article class="prop-map section content" intersection-observer observeMargin="40px" [onObservedCallback]="onShowStreetview">
        <h2 class="small">{{ propertyDetails.address }} - Streetview</h2>
        <property-map *ngIf="showStreetview" [latLng]="this.latLng" mode="EMBED" isStreetView="true"></property-map>
        <link-button afterIcon="next" class="more-properties" title="See more properties like this one" i18n-title="See_More_Properties_Like_This_One|See more properties like this one" border="true" fullWidth="true" size="small" [href]="propertyDetails.similarPropertiesSearchUrl"></link-button>
        <affiliate [affiliateLogoClass]="'credito'" caption="Check your credit score" link="https://www.creditengine.co.uk/?campaign_code=cF5ZYAMFAw&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_detail"></affiliate>
    </article>

    <!-- Powered by -->
    <article class="section content powered-by">
        <div class="caption ">DISCLAIMER: Property descriptions and related information displayed on this page are marketing materials provided by {{this.propertyDetails.agentName}}. Placebuzz does not warrant or accept any responsibility for the accuracy or completeness of the property descriptions or related information provided here and they do not constitute property particulars. Please contact {{this.propertyDetails.agentName}} for full details and further information.</div>
        <pb-image [src]="propertyDetails.poweredByUrl" imageWidth="64" imageHeight="64" width="100px" height="30px" [alt]="propertyDetails.title + ' - powered by'"></pb-image>
        
    </article>
</div>