<div class="main-pane" >
        
    <div class="results-list">

        <ad-unit [showOnDesktop]="false" page="search_results" adPosition="result_header"></ad-unit>

        <div *ngIf="propertyListings?.listings.length===0" class="no-matches">
            <h3 class="small">No properties found matching your search options</h3>
            <label>Please adjust your search options or search in another area</label>
            <div *ngIf="propertyListings?.placeSearchCriteria?.hasAlerts">
                <p>You can also turn on alerts and we can let you know as soon as new properties matching your search options are listed here</p>
                <pb-button extraClasses="turn-on-alerts" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="Turn on alerts" leftIcon="bell" (clicked)="OnTurnOnAlertsClicked()"></pb-button>
            </div>
        </div>

        <ng-container *ngFor="let listing of propertyListings?.listings; let i = index">
            

            <div *ngIf="i==6" intersection-observer observeMargin="20px" [onObservedCallback]="onAveragePricesShown">
                <average-price-box [averagePrices]="averagePrices" [searchType]="propertyListings.placeSearchCriteria.searchTypeId==1?'rent':'sale'" [searchArea]="propertyListings?.placeSearchCriteria.searchArea"></average-price-box>
            </div>
            

            <area-guide-box *ngIf="i==5 && this.propertyListings?.areaGuide && this.propertyListings.areaGuide.averageScore > 0 && this.propertyListings?.placeSearchCriteria?.page==1" [propertyListings]="this.propertyListings"></area-guide-box>

            <affiliate *ngIf="i==0" affiliateLogoClass='credito' caption="Check your credit score" link="https://www.creditengine.co.uk/?campaign_code=cF5ZYAMFAw&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_property_listing"></affiliate>    
            
            <property-listing [forceLoadImage]="i<3" [listing]="listing" mode='list' [isFavourite]="listing.isFavourite===true" [isSponsored]="listing.isSponsored"> </property-listing>
            
            <ad-unit *ngIf="i==1" page="search_results" adPosition="result_incontent_1"></ad-unit>
            <ad-unit *ngIf="i==3" page="search_results" adPosition="result_incontent_2"></ad-unit>


            <div *ngIf = "!isSearchSaved && i==2" class="save-search">
                <svg height="64" viewBox="0 0 512 512" width="64" xmlns="http://www.w3.org/2000/svg"><g id="_18_Mail" data-name="18 Mail"><g fill="#ffebb9"><path d="m101.58 146.31v108.33l-49.38-54.17z"/><path d="m355.43 86h-198.86l42.68-46.8a76.8 76.8 0 0 1 113.51 0z"/><path d="m459.81 200.47-44.37 48.67v-97.34z"/></g><path d="m415.44 111v138.15l-91.2 100-11.48-12.6a76.79 76.79 0 0 0 -113.51 0l-11.49 12.6-86.19-94.54v-143.61a25 25 0 0 1 25-25h263.87a25 25 0 0 1 25 25z" fill="#f7f5fb"/><path d="m354.78 239.66v64.14a23.39 23.39 0 0 1 -23.39 23.39h-28.88a76.85 76.85 0 0 0 -93 0h-28.91a23.39 23.39 0 0 1 -23.39-23.39v-64.14l91-76.34a12.16 12.16 0 0 1 15.61 0z" fill="#cfdce5"/><path d="m368.59 245.88a17.6 17.6 0 0 1 -11.33-4.13l-93.45-78.42a12.15 12.15 0 0 0 -15.62 0l-93.45 78.42a17.63 17.63 0 0 1 -22.67-27l112.55-94.45a17.6 17.6 0 0 1 11.38-4.13h.1a17.6 17.6 0 0 1 11.33 4.13l112.55 94.44a17.63 17.63 0 0 1 -11.34 31.14z" fill="#f46969"/><path d="m293.17 242.77v78.32a77 77 0 0 0 -74.35 0v-78.32a10.84 10.84 0 0 1 10.9-10.77h52.55a10.84 10.84 0 0 1 10.9 10.77z" fill="#f46969"/><path d="m187.76 349.18-115.69 126.89a70.86 70.86 0 0 1 -19.87-49.32v-226.27z" fill="#f5d367"/><path d="m439.93 476.07a70.94 70.94 0 0 1 -51.24 21.8h-265.37a71 71 0 0 1 -51.25-21.8l115.69-126.89 11.49-12.6a76.79 76.79 0 0 1 113.51 0l11.48 12.6z" fill="#f4d787"/><path d="m459.8 200.48v226.27a70.86 70.86 0 0 1 -19.87 49.32l-115.69-126.89z" fill="#f5d367"/><path d="m128.85 413.79-56.78 62.28a70.87 70.87 0 0 1 -19.88-49.32v-98.12a502 502 0 0 0 76.66 85.16z" fill="#e2c061"/><path d="m260.89 497.87h-137.57a70.93 70.93 0 0 1 -51.25-21.8l56.78-62.28a500.14 500.14 0 0 0 132.04 84.08z" fill="#e5c67a"/><path d="m281.37 285.53h-9.79a6 6 0 1 1 0-12h9.79a6 6 0 0 1 0 12z" fill="#4a5058"/></g></svg>
                <h3 class="small">Get new properties direct in your inbox</h3>
                <p>Save this search to get instant alerts when properties are listed</p> 
                <pb-button leftIcon="mail" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="Save search" (clicked)="OnSaveSearchClicked()"></pb-button>
            </div>

            <ad-unit *ngIf="i==6" page="search_results" adPosition="result_incontent_3"></ad-unit>
            <ad-unit *ngIf="i==10" page="search_results" adPosition="result_incontent_4"></ad-unit>
            
        </ng-container>
    
        <pagination *ngIf="propertyListings?.pagination?.items"  [totalPages]="propertyListings.pagination.pageLastItemNumber" [items]="propertyListings.pagination.items" (pageChanged)="onPageChanged($event)" enablePageChangedEvent="true"> </pagination>
        
        <breadcrumb *ngIf="propertyListings?.breadcrumbList" [linkList]="propertyListings.breadcrumbList"></breadcrumb>

        <div class="save-bottom" *ngIf="!isSearchSaved">
            <pb-button leftIcon="mail" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="Create email alert" (clicked)="OnSaveSearchClicked()"></pb-button>
            <pb-button leftIcon="heart" buttonStyle="primary"  buttonSize="regular" iconColour="#4a4a4a" caption="Save this search " (clicked)="OnSaveSearchClicked()"></pb-button>
        </div>

        <affiliate [affiliateLogoClass]="'credito'" caption="Check your credit score" link="https://www.creditengine.co.uk/?campaign_code=cF5ZYAMFAw&utm_source=placebuzz&utm_medium=affiliate&utm_campaign=placebuzz_property_listing"></affiliate>

        <related-searches *ngIf="propertyListings?.relatedSearches"  [datasource]=propertyListings.relatedSearches title="Searches relating to&nbsp;{{propertyListings.heading}}" trackingkey="Related Search" (relatedSearchClick)="navigateToPage($event)"></related-searches>
    
    </div>

    <div class="side-bar">
        
        <related-searches class="space-out" (relatedSearchClick)="navigateToPage($event)" compact="true" *ngIf="propertyListings?.neighbourhoods && propertyListings?.neighbourhoods.length>0" [datasource]=propertyListings.neighbourhoods title="Within&nbsp;{{propertyListings.placeSearchCriteria.searchArea}}" trackingkey="Neighbourhood"></related-searches>
        
        <related-searches class="space-out" (relatedSearchClick)="navigateToPage($event)" compact="true" *ngIf="propertyListings?.nearbyLocalities && propertyListings?.nearbyLocalities.length>0" [datasource]=propertyListings.nearbyLocalities title="Near&nbsp;{{propertyListings.placeSearchCriteria.searchArea}}" trackingkey="Locality"></related-searches>

        <section *ngIf="propertyListings?.reverseListingTypeLink" class="space-out">
            <link-button title="{{propertyListings.reverseListingTypeLink.title}}" afterIcon="chevronright"  [href]="propertyListings.reverseListingTypeLink.url" size="small"></link-button>
        </section>

        <section *ngIf="this.getBreakpoint()=='desktop'" class="sideAd">
            <div  class="ad-wrapper-skyscrapper">
                <ad-unit [showOnMobile]="false" page="search_results" adPosition="result_vrec_1"></ad-unit>
            </div>
        </section>

    </div>
</div>
